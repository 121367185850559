<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col :cols="9">
          <v-row dense>
            <v-col key="toneladasAno" :cols="4">
              <v-card
              :loading="loaders.totalRecolhidoAno">
                <v-card-title class="pb-0 pt-1">
                  Total Ano (T): {{ dateFormatted.getFullYear() }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">{{ (toneladaAno / 1000).toLocaleString("pt-PT") }}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col key="higienizacoesPendentes" :cols="4">
              <v-card
              :loading="loaders.higienizacoesPendentes">
                <v-card-title class="pb-0 pt-1">
                  Higienizações Pendentes:
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">
                    {{higienizacoesPendentes}}
                  </h1>
                </center>
              </v-card>
            </v-col>
            <v-col key="monstrosPendentes" :cols="4">
              <v-card
              :loading="loaders.monstrosPendentes">
                <v-card-title class="pb-0 pt-1">
                  Monstros Pendentes:
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">
                    {{monstrosPendentes}}
                  </h1>
                </center>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col key="toneladasMes" :cols="4">
              <v-card
              :loading="loaders.totalRecolhidoMes">
                <v-card-title class="pb-0 pt-1">
                  Total Mês (T): {{ meses[dateFormatted.getMonth()] }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">{{ (toneladaMes / 1000).toLocaleString("pt-PT") }}</h1>
                </center>
              </v-card>
            </v-col>
            <v-col key="manutencoesPendentes" :cols="4">
              <v-card
              :loading="loaders.manutencoesPendentes">
                <v-card-title class="pb-0 pt-1">
                  Manutenções Pendentes:
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">
                    {{manutencoesPendentes}}
                  </h1>
                </center>
              </v-card>
            </v-col>
            <v-col key="agendamentosPendentes" :cols="4">
              <v-card
              :loading="loaders.agendamentosPendentes">
                <v-card-title class="pb-0 pt-1">
                  Agendamentos Pendentes:
                  <v-spacer></v-spacer>
                </v-card-title>
                <center>
                  <h1 class="secondary--text">
                    {{agendamentosPendentes}}
                  </h1>
                </center>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="3">
          <v-card height="100%" class="text-center px-3"
            :loading="loaders.mostrarTrajeto">
            <v-card-title>
              Mostrar trajeto
              <v-spacer></v-spacer>
            </v-card-title>
            <div>
              <v-menu
                ref="menuDataRota"
                v-model="menuDataRota"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                style="z-index: 9999">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color=primary
                    small
                    v-on="on">
                    Data da Rota:
                    {{
                      dataPercurso.split("-")[2] +
                      "/" +
                      dataPercurso.split("-")[1] +
                      "/" +
                      dataPercurso.split("-")[0]
                    }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataPercurso"
                  locale="pt-pt"
                  min="2020-01-01"
                  :max="dateToYYYYMMDD(dateFormatted)"
                  no-title
                  @input="menuDataRota = false"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mt-5">
              <template>
                <v-select
                  :items="caminhoes"
                  dense
                  menu-props="auto"
                  label="Matrículas"
                  item-text="matricula"

                  v-model="camiao"
                  return-object
                  clearable
                  @click:clear.stop="getCarTrack()">
                </v-select>
              </template>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col key="Histórico" :cols="6">
          <v-card>
            <v-card-title>
              Últimos Reports
            </v-card-title>
            <v-card-subtitle>
              Atualizado em:
              {{
                latestDate.toLocaleDateString("pt-PT") +
                " " +
                latestDate.toLocaleTimeString("pt-PT")
              }}
            </v-card-subtitle>
            <v-data-table
              :headers="historicoHeader"
              :items="historico"
              :loading="loaders.ultimosReports"
              loading-text="A carregar..."
              :search="search"
              :single-expand=true
              :expanded.sync="expanded"
              show-expand
              item-key="index"
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="background-color:#616161">
                  <v-row>
                    <v-col class="text-center" :cols="2">
                      <b>Id:</b>
                      <div>
                        {{ item.id }}
                      </div>
                    </v-col>

                    <v-col class="text-center" :cols="2">
                      <b>Sensor:</b>
                      <div>
                      <v-icon
                        title="Sensor Ligado"
                        color="green"

                        v-if="item.sensor">
                        mdi-wifi
                      </v-icon>
                      <v-icon title="Sem Sensor" color="grey" v-if="!item.sensor">
                        mdi-wifi
                      </v-icon>
                    </div>
                    </v-col>

                    <v-col class="text-center" :cols="3">
                      <b>Rota:</b>
                      <div>
                        {{ item.rota }}
                      </div>
                    </v-col>

                    <v-col class="text-center" :cols="5">
                      <b>Reports:</b>
                      <div>
                        <v-icon
                          title="Lixo recolhido"
                          large

                          :color="item.recolheuLixo ? 'green' : 'grey'"
                          tile>
                          mdi-check
                        </v-icon>
                        <v-icon
                          title="Lixo fora do contentor"

                          :color="item.lixofora ? '#ebbf41' : 'grey'"
                          tile>
                          mdi-basket-unfill
                        </v-icon>
                        <!--<v-icon
                          title="Tampa Partida"

                          :color="item.tampaPartida ? '#347a8d' : 'grey'"
                          tile>
                          mdi-delete-empty
                        </v-icon>-->
                        <v-icon
                          :color="item.temAvaria ? '#f24747' : 'grey'"
                          title="Tem Anomalia">
                          mdi-delete-forever
                        </v-icon>
                        <v-icon
                          title="Precisa de Higienização"

                          :color="item.precisaLimpeza ? '#fa8748' : 'grey'"
                          tile>
                          mdi-broom
                        </v-icon>
                        <v-icon
                          title="Tem lixo grosso"

                          :color="item.monstros ? '#6e6eba' : 'grey'">
                          mdi-seat
                        </v-icon>
                        <v-icon
                          title="Fez higienização"

                          :color="item.fezHigenizacao ? '#ff00ff' : 'grey'"
                          tile>mdi-spray-bottle
                        </v-icon>
                        <v-icon
                          title="Recolheu Monstro"

                          :color="item.recolheuMostros ? '#3366ff' : 'grey'"
                          tile>mdi-arrow-collapse-up
                        </v-icon>
                        <v-icon
                          title="Fez Manutenção"

                          :color="item.fezManutencao ? '#ffffff' : 'grey'"
                          tile>mdi-delete-circle-outline
                        </v-icon>
                        <br />
                        <span v-if="item.obsLixo" class="mx-2">
                          <b>Obs:</b> {{ item.obsLixo }}
                        </span>
                        <span v-if="item.obsAvaria" class="mx-2">
                          <b>Anomalia:</b> {{ item.obsAvaria }}
                        </span>
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="6">
                      <b>Rua:</b>
                      <div>
                        {{ item.rua }}
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="3">
                      <b>Camião:</b>
                      <div>
                        {{ item.caminhao }}
                      </div>
                    </v-col>
                    <v-col class="text-center" :cols="3">
                      <b>Motorista:</b>
                      <div>
                        {{ item.motorista }}
                      </div>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template v-slot:[`item.fotos`]="{ item }">
                <a
                  v-for="foto in item.fotos"
                  :key="`foto-${foto.id}`"
                  :href="imagemFoto(foto.url)"
                  target="_blank">
                  <img :src="imagemFoto(foto.url)" height="70px" width="70px" />
                </a>
              </template>

              <template v-slot:[`item.nivel`]="{ item }">
                <v-chip
                  :color="getColorNivelSensorTabela(item.nivel)"

                  v-if="item.nivel">
                  {{ item.nivel }}%
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col :cols="6">
          <v-card style="height: 600px; width: 100%"
            :loading="loaders.mostrarTrajeto">
            <v-card-title>
              Mapa
              <v-spacer></v-spacer>
            </v-card-title>
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              v-on:update:zoom="updateZoom($event)"
              ref="mymap">
              <l-polyline
                v-if="cartrack"
                :lat-lngs="cartrack"
                color="#347a8d">
              </l-polyline>
              <l-geo-json
                v-if="geojson"
                :geojson="geojson"
                :options-style="myStyle">
              </l-geo-json>
              <l-tile-layer :url="url" :attribution="attribution" />
              <template v-if="caminhoes">
                <l-marker
                  v-for="marker in caminhoes"
                  :key="`camioes-${marker.id}`"
                  :lat-lng="getlatLng(marker.lat, marker.lng)">
                  <l-icon
                    :icon-size="dynamicSizeCamiao"
                    :icon-anchor="dynamicAnchorCamiao"
                    :icon-url="API_URL+'/img/caminhao/normal.png'"/>
                  <l-popup>
                    <div v-if="marker.data_cartrack">
                      Caminhao: {{ marker.matricula }}
                      <br />
                      Última Atualização:
                      {{
                        marker.data_cartrack.split("-")[2].substr(0, 2) +
                        "/" +
                        marker.data_cartrack.split("-")[1] +
                        "/" +
                        marker.data_cartrack.split("-")[0] +
                        " " +
                        marker.data_cartrack.split("-")[2].substr(2, 9)
                      }}
                      <br />
                      <p>
                        <br />
                      </p>
                    </div>
                  </l-popup>
                </l-marker>
              </template>
              <l-marker
                :lat-lng="getlatLng(41.38150949313275, -8.380767029624579)">
                <l-icon
                  :icon-size="dynamicSizeCamiao"
                  :icon-anchor="dynamicAnchorCamiao"
                  :icon-url="API_URL+'/img/resinorte.png'"/>
                <l-popup>
                  <div>Resinorte</div>
                </l-popup>
              </l-marker>
            </l-map>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { icon, latLng } from "leaflet";
/* eslint-disable */
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import mixin from '../plugins/mixin';

export default {
  name: "Login",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LIcon,
    LPolyline,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    menuDataRota: "",
    pesquisaContentor: "",
    meses: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10],
    },
    addContentor: false,
    higienizacoesPendentes: 0,
    monstrosPendentes: 0,
    manutencoesPendentes: 0,
    agendamentosPendentes: 0,
    toneladaAno: 0,
    toneladaMes: 0,
    dataPercurso: new Date().toISOString().substr(0, 10),
    dateFormatted: new Date(),
    myStyle: {
      weight: 1,
      color: "#7366aa",
      fillColor: "#92abd2",
    },
    rotas: [],
    cartrack: [],
    search: "",
    rotasHeader: [{ text: "Nome", value: "nome" }],
    rotasselected: [],
    historicoHeader: [
      { text: "Contentor", value: "contentor", align: 'center' },
      { text: "Data", value: "data", align: 'center' },
      { text: "Freguesia", value: "freguesia", align: 'center' },
      { text: "Circuito", value: "tipo_circuito", align: 'center' },
      { text: "Último Nivel", value: "nivel", align: 'center' },
      { text: "Fotos", value: "fotos", align: 'center' },
    ],
    historico: [],
    latestDate: new Date(),
    reports: {},
    selecionado: {},
    markers: [
      {
        id: "m1",
        position: { lat: 47.41322, lng: -1.2 },
        tooltip: "tooltip for marker1",
        icon: icon.glyph({
          prefix: "mdi",
          glyph: "trash-can-outline",
        }),
      },
    ],
    caminhoes: [],
    zoom: 11,
    center: latLng(41.456535, -8.316106, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    geojson: null,
    iconSize: 17,
    iconSizeCamiao: 30,
    expanded: [],
    tipoCircuito: [],
    camiao: null,
    loaders: {
      totalRecolhidoAno: false,
      totalRecolhidoMes: false,
      monstrosPendentes: false,
      higienizacoesPendentes: false,
      manutencoesPendentes: false,
      agendamentosPendentes: false,
      ultimosReports: false,
      mostrarTrajeto: false,
    },
  }),
  watch: {
    date() {
      this.dataPercurso = this.formatDate(this.date);
    },
    dataPercurso() {
      if (this.camiao) {
        this.getCarTrack(this.camiao.id_cartrack);
      }
    },
    camiao: function(obj) {
      if (obj) { //Used to clean the map of routes
        this.getCarTrack(obj.id_cartrack);
      }
    }
  },
  methods: {
    filtroDaBusca(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        item.valoresDePesquisa.indexOf(search) !== -1
      );
    },
    getToneladasResinorte() {
      this.toneladaAno = 0;
      this.toneladaMes = 0;
      this.loaders.totalRecolhidoAno = true;
      this.loaders.totalRecolhidoMes = true;

      this.$http
        .get('graphql?query={\
              kmsConnection (where: { \
                              tipo:"descarga", \
                              created_at_gte:"'+new Date().getFullYear()+'-01-01T00:00:00.000Z", \
                              created_at_lte:"'+new Date().getFullYear()+'-12-31T23:59:59.999Z" \
                            }){ \
                  aggregate { \
                      sum { \
                          quantidade \
                      } \
                  } \
              } \
            }',
        {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.toneladaAno = response.data.data.kmsConnection.aggregate.sum.quantidade;
          this.loaders.totalRecolhidoAno = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.totalRecolhidoAno = false;
        });
        var today = new Date();
        var firstDayOfMonth = today.getFullYear()+'-'+('0'+(today.getMonth()+1))+'-'+'01';
        var lastDayOfMonth = new Date(today.getFullYear(), (today.getMonth()+1), 0);
        lastDayOfMonth = lastDayOfMonth.getFullYear()+'-'+('0'+(lastDayOfMonth.getMonth()+1)).slice(-2)+'-'+lastDayOfMonth.getDate()
        this.$http
          .get('graphql?query={\
                kmsConnection (where: { \
                                tipo:"descarga", \
                                created_at_gte:"'+firstDayOfMonth+'T00:00:00.000Z", \
                                created_at_lte:"'+lastDayOfMonth+'T23:59:59.999Z" \
                              }){ \
                    aggregate { \
                        sum { \
                            quantidade \
                        } \
                    } \
                } \
              }',
          {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            this.toneladaMes = response.data.data.kmsConnection.aggregate.sum.quantidade;
            this.loaders.totalRecolhidoMes = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.totalRecolhidoMes = false;
          });
    },
    getPendentes(){
      this.loaders.monstrosPendentes = true;
      this.loaders.higienizacoesPendentes = true;
      this.loaders.manutencoesPendentes = true;
      this.loaders.agendamentosPendentes = true;

      this.$http
        .get("/contentors/needClean/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.higienizacoesPendentes = response.data.length;
          this.loaders.higienizacoesPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.higienizacoesPendentes = false;
        });
      this.$http
        .get("/contentors/haveMonster/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.monstrosPendentes = response.data.length;
          this.loaders.monstrosPendentes = false;
        })
        .catch(error => {
          console.log(error);
          this.loaders.monstrosPendentes = false;
        });
      this.$http
        .get("/contentors/needMaintenance/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.manutencoesPendentes = response.data.length;
          this.loaders.manutencoesPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.manutencoesPendentes = false;
        });
      this.$http
        .get('graphql?query={ \
          agendamentosConnection (where: { _or: [{recolhido: false}, {recolhido_null: true}] cancelado: false}) { \
            aggregate { \
              count } \
          } \
        }', {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.agendamentosPendentes = response.data.data.agendamentosConnection.aggregate.count;
          this.loaders.agendamentosPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.agendamentosPendentes = false;
        });
    },
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
    getTipoCircuito(){
      this.$http
        .get('graphql?query={ \
          tipoCircuitos {\
            id, \
            circuito \
          } \
        }',
        {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.tipoCircuito = response.data.data.tipoCircuitos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCircuitoName(report){
      if(report.tipo_circuito){
        return report.tipo_circuito.circuito;
      }
      else if (report.rota){
        return this.tipoCircuito.filter((tipo) => {
          return tipo.id == report.rota.tipo_circuito;
        }) [0].circuito;
      }
      else
        return "";
    },
    updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position, zoom = 17) {
      this.zoom = zoom;
      this.zoomUpdate = zoom;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    imagemFoto(imagem) {
      return this.API_URL+`${imagem}`;
    },
    getReports() {
      this.loaders.ultimosReports = true;
      this.$http
        .get('graphql?query={reports(limit:100,sort:"data:desc"){id,recolheuLixo,tampaPartida,lixofora,temAvaria,precisaLimpeza, \
              monstros,fezHigenizacao,recolheuMostros,data,nivel,obsAvaria,obsLixo,fotos{id,url}contentor{id,sensor,numero_contentor, \
              rua,localidade{id,designacao}},caminhao{id,matricula},user{nome},rota{nome,tipo_circuito{id,circuito}}, \
              tipo_circuito{id,circuito}}}', {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.historico = [];
          this.reports = response.data;
          response.data.data.reports.forEach((i, index) => {
            this.historico.push({
              index: index,
              id: i.id,
              sensor: i.contentor?.sensor ? true : false,
              caminhao: i.caminhao ? i.caminhao.matricula : "",
              motorista: i.user?.nome,
              rota: i.rota ? i.rota.nome : "",
              recolheuLixo: i.recolheuLixo,
              report: true,
              tampaPartida: i.tampaPartida,
              lixofora: i.lixofora,
              temAvaria: i.temAvaria,
              precisaLimpeza: i.precisaLimpeza,
              monstros: i.monstros,
              fezHigenizacao: i.fezHigenizacao,
              recolheuMostros: i.recolheuMostros,
              fezManutencao: i.fezManutencao,
              data: new Date(i.data).toLocaleString(),
              contentor: i.contentor?.numero_contentor,
              rua: i.contentor?.rua,
              nivel: i.nivel,
              fotos: i.fotos,
              position: "",
              obsAvaria: i.obsAvaria,
              obsLixo: i.obsLixo,
              tipo_circuito: this.getCircuitoName(i),
              freguesia: i.contentor?.localidade.designacao,
            });
          });
          this.latestDate = new Date();
          this.loaders.ultimosReports = false;
        })
      .catch((error) => {
          console.log(error);
          this.loaders.ultimosReports = false
        });
    },
    permissao() {
      return this.getObjeto("user").role.name == "Administrador";
    },
    getCaminhoes() {
      this.$http
        .get("camioes", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.caminhoes = response.data;
        });
    },
    getRota() {
      this.$http
        .get("rotas", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.rotas = response.data;
        });
    },
    getCarTrack(id) {
      if (!id) {
        this.cartrack = undefined;
      } else {
        this.loaders.mostrarTrajeto = true;
        this.$http
          .get("arcgis/camioes/" + id + "/" + this.dataPercurso, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            this.cartrack = response.data;
            // this.centerZoom(this.center);
            this.loaders.mostrarTrajeto = false;
          })
          .catch(() => {
            // handle error
            this.cartrack = undefined;
            this.centerZoom(this.center);
            this.loaders.mostrarTrajeto = false;
          });
      }
    },
    error401logout(error) {
      if (error.status == 401) {
        this.dialog = false;
        localStorage.clear();
        this.$router.push("/login");
      }
    },
  },

  created() {
    this.getTipoCircuito();
    this.getToneladasResinorte();
    this.getReports();
    this.getCaminhoes();
    this.getPendentes();
    this.timer = setInterval(this.getReports, 1000 * 60);
    this.timer = setInterval(this.getCaminhoes, 1000 * 60 * 2); //atualiza a cada 2 min
    this.$http
      .get(this.API_URL+"/guimaraes.geojson")
      .then((response) => {
        this.geojson = response.data;
      });
  },
  mounted() {

    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 500);

    const map = this.$refs.mymap.mapObject;

    map.addControl(new L.Control.Fullscreen());
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

    dynamicSizeCamiao() {
      return [this.iconSizeCamiao, this.iconSizeCamiao * 1.15];
    },
    dynamicAnchorCamiao() {
      return [this.iconSizeCamiao / 2, this.iconSizeCamiao * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
};
</script>
<style scoped>
  thead tr {
    background-color: #303030;
  }

  .v-data-table-header {
    background-color: #303030;
  }
  .v-data-footer__select {
    min-width: 3em;
    display: none;
  }
</style>
<style>
  .v-data-footer__select {
    min-width: 3em;
    display: none;
  }
</style>
