import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#92abd2',
        secondary: '#7366aa',
        background: '#c4daff',
      },
      dark: {
        primary: '#92abd2',
        secondary: '#7366aa',
        background: '#c4daff',
      },
    },
  },
}

export default new Vuetify(opts)