
<template >
  <div>
    <v-app id="inspire" v-if="$route.path != '/login'">
      <v-navigation-drawer v-model="drawer" app temporary>
        <br /><br /><br />
        <v-list dense style="margin-top: 40px!important;"><!--style temporario-->
          <v-list-item @click="navegar('/')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-home
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Home
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navegar('contentores')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-trash-can-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Contentores
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-trash-can-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Contentores SOPSA
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navegar('agendamentos')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-calendar-month-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Agendamentos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navegar('camiao')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-dump-truck
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Camiões
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-map-marker-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Alertas Proximidade
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-car-clock
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Parquimetros
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-office-building
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Higienização edifícios
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navegar('insertCircuitos')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-map-plus
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Rotas
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="permissao()" @click="navegar('utilizador')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-account
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Utilizadores
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--
          <v-list-item @click="navegar('/reportRealTime')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-history
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Histórico de Reports
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->
          <v-list-item @click="navegar('report')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-clipboard-list-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Relatório por Rota
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navegar('reportDiario')">
            <v-list-item-action>
              <v-icon color="secondary">
                mdi-clipboard-list-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Histórico de Reports
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app style="z-index: 9995" :style="{background: $vuetify.theme.themes[theme].background}">
        <!--
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        -->
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>
          <img style="max-width: 60px; padding-top: 10px" src="img/urbansmart_short.png" />
        </v-toolbar-title>
        <div
          class="d-flex justify-md-center padding-top: 8px;"
          style="padding-left: 30%; color: #7366aa"
          outlined>
          <h3 style="padding-right: 10px; padding-top: 15px;">SmartWaste by </h3>
          <img style="max-height: 60px;" src="img/urbansmart_full.png" />
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="dialog = true">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
        <!--
        <template v-slot:extension>
          <v-alert ref="licenseWarning" dense type="error" style="width: 100%!important;margin:0!important;border-radius:0!important;text-align:center!important;font-size: 14px;z-index: 9995;">
            O licenciamento da aplicação está prestes a terminar. Contacte os nossos serviços comercias (<a style="color:white" href="geral@nipstechnologie.pt">geral@nipstechnologie.pt</a>) até 15 de Novembro de 2021!
          </v-alert>
        </template>
        -->
      </v-app-bar>
      <v-main class="pt-0">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
        <v-dialog v-model="dialog" max-width="290" style="z-index: 9999">
          <v-card>
            <v-card-title class="headline">Deseja sair?</v-card-title>
            <v-card-text></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                Não
              </v-btn>
              <v-btn color="green darken-1" text @click="logout()">Sim</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
      <v-footer app style="z-index: 9995; box-shadow: 0 -2px 4px -1px rgba(0,0,0,.2), 0 -4px 5px 0 rgba(0,0,0,.14), 0 -1px 10px 0 rgba(0,0,0,.12);"
        :style="{background: $vuetify.theme.themes[theme].background}">
        <span class="white--text" style="padding-right: 5px">v{{appVersion}} &copy; 2023</span>
        <img style="max-height: 40px;" src="img/urbansmart_full.png" />
        <span>
          <a href="https://www.urbansmartlab.com" target="_blank" style="text-decoration: none;">www.UrbanSmartLab.com</a> -
          <a href="https://www.uslab.pt" target="_blank" style="text-decoration: none;">www.USLab.pt</a> -
          <a href="mailto:info@urbansmartlab.com" target="_blank" style="text-decoration: none;">info@urbansmartlab.com</a> -
          <a href="tel:+351253195021" target="_blank" style="text-decoration: none;">+351 253195021</a>
        </span>
        <h6 style="position: absolute; right: 0px; margin-right:10px;">Licença: UrbanSmartLab – NIF 517308983</h6>
      </v-footer>
    </v-app>
    <router-view v-if="$route.path == '/login'"></router-view>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import axios from "./plugins/axios";
import {version} from '../package.json';

export const contentores = [];

export default Vue.extend({
  name: "App",
  props: {
    source: String,
  },
  data: () => ({
    appVersion: version,
    API_URL: process.env.VUE_APP_API_URL,
    fullscreen: false,
    titulo: "NipsTechnologie.pt",
    dialog: false,
    timer: "",
    gps: {},
    drawer: false,
    drawerRight: null,
    right: false,
    left: false,
  }),
  watch: {},
  created() {
    if (this.$route.path != "/login") {
      if (!this.getObjeto("token")) {
        localStorage.clear();
        this.$router.push("/login");
      }
    }
    this.$nextTick(() => {
      //this.$refs.licenseWarning.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' });
    });
  },
  methods: {
    permissao() {
      return this.getObjeto("user").role.name == "Administrador";
    },
    logout() {
      this.dialog = false;
      localStorage.clear();
      this.$router.push("/login");
    },
    navegar(navegar) {
      this.$router.push(navegar);
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
});
</script>

<style>
  main {
    height: calc(100% - 60px);
  }
  .v-chip{
    width: 100%;
    text-align: center;
  }
  .v-chip span{
    margin: 0 auto;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 5px !important;
  }
  .v-app-bar.v-app-bar--fixed {
    position: sticky !important;
  }
  v-main{
    height: 100% !important;
  }
</style>

<style scoped>
.v-content {
  /*background-color: black;*/
}
.v-data-table-header {
  background-color: #303030;
}

thead tr {
  background-color: #303030;
}
</style>
